import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/NFT1.jpeg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="about">
                    <div class="storyCon">
                        <div class="conT">ABOUT</div>
                        <p>Forget about the Frogs, Dogs and Inus they are old news. The aliens have landed ;) But dont get too excited, they don't like what they see here on Earth so they will be looking to go back home. Their jurney back to the Moon starts now. Don't let them go alone, buy some $ZALIEN and go to the Moon with them :)</p>
                        <p>Launched stealth with no presale, zero taxes, LP burnt and contract renounced, $ZALIEN is a coin for the people, forever. Fueled by pure memetic power, let $ZALIEN show you the way.</p>
                    </div>
                    
                    <img src={nft} />

                </div>

            </div>
        )
    }
}

export default Story;

