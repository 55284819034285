import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/logo.png';
import Ticker from './ticker';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import discord from '../assets/discord.png';

const opensea = () => {
    window.open("https://opensea.io/collection/zalien-nfts");
}

const tweet = () => {
    window.open("https://twitter.com/ZalienSpace");
}

const tg = () => {
    window.open("#");
}

const element = () => {
    window.open("#");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">
 
                <div class="footer">

                    <img class="logoF" src={logo} />
                    <div class="icons">
                        <img onClick={opensea} src={os} />
                        <img onClick={element} src={discord} />
                        <img onClick={tweet} src={twitter} />

                    </div>

                    <div class="copyright">Copyright © 2023 ZALIEN. All Rights Reserved</div>
                </div>

            </div>
        )
    }
}

export default Footer;

