import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Buy extends Component {

    render() {
        return (

            <div class="boxWrapBuy">
                <div class="conT2">HOW TO BUY</div>
                <div class="buyMain">
 
                    <div class="storyCon2">
                        <div class="conT3">Step 1 - Create a Wallet</div>
                        <p>Download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io.</p>

                </div>
                
                    <div class="storyCon2">
                        <div class="conT3">Step 2 - Get Some ETH</div>
                        <p>Have ETH in your wallet to switch to $ZALIEN. If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet.
                        </p>
                    </div>

                    <div class="storyCon2">
                        <div class="conT3">Step 3 - Go to Uniswap</div>
                        <p>Connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $ZALIEN token address into Uniswap, select zAlien , and confirm. When Metamask prompts you for a wallet signature, sign.</p>
                        <p>Switch ETH for $PEPE</p>
                        <p>Switch ETH for $ZALIEN. We have ZERO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.</p>
                    </div>


                </div>

            </div>
        )
    }
}

export default Buy;

