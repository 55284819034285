import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import '../newRoadmap.css'
import icon from '../assets/iconrm.png';
import icon2 from '../assets/galaxy.png';

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});
function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 15;

        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
        } else {
            reveals[i].classList.remove("active");
        }
    }
}

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});


class Team extends Component {

    async componentDidMount() {

    }

    render() {
        return (
            <div class="boxWrap2Rm">

                <div class="conT2">Roadmap</div>

                <div class="timeline-container">
                    <ul class="vertical-scrollable-timeline">
                        <li>
                            <h2>Phase 1: <span class="smallL">NFT Drop – 5% of Feel To The Liquidity Wallet</span></h2>
                            <p><img class="rmNote" src={icon2} />1,000 Holders</p>
                            <p><img class="rmNote" src={icon2} />LQ Lock</p>
                            <p><img class="rmNote" src={icon2} />Renounce Contract</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />

                            </div>
                        </li>
                        <li>
                            <h2>Phase 2: <span class="smallL">Chill and HODL</span></h2>

                            <p><img class="rmNote" src={icon2} />Marketing</p>
                            <p><img class="rmNote" src={icon2} />CMC/Cingecko listing</p>

                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>
                        <li>
                            <h2>Phase 3: <span class="smallL">Meme Takeover</span></h2>

                            <p><img class="rmNote" src={icon2} />CEX listing</p>
                            <p><img class="rmNote" src={icon2} />Partnerships</p>
                            <p><img class="rmNote" src={icon2} />Merch</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                        <li>
                            <h2>Phase 4: <span class="smallL">Takeoff - Expect surprises along the way ;)</span></h2>

                            <div class="icon-holder">
                                <img class="footPrint2" src={icon} />
                            </div>
                        </li>

                    </ul>
                </div>

            </div>
        )
    }
}

export default Team;